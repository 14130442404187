import ModalCustom from "../../../Modal/ModalCustom";
import successLight from "../../../../assets/images/success-light.svg";
import successDark from "../../../../assets/images/success-dark.svg";
import "./genAiModal&Button.css";
import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../../../../ThemeContext.jsx";
import "./twinkle.css";
import TopicCardValidations from "../../../../validationClasses/TopicCardValidations.js";

const GenAiModal = (props) => {
  const [savedDescription, setSavedDescription] = useState(false);
  const [theme] = useContext(ThemeContext);

  useEffect(() => {
    if (savedDescription) {
      setTimeout(() => {
        props.closeModal();
      }, 3000);
    }
  }, [savedDescription]);

  const handleAccept = (e) => {
    let isValid = false;

    switch (props.validationType) {
      case "BB_Validations":
        isValid = props.BB_Validations.validateDescription(
          props.lastGeneratedDescription
        ).isValid;
        break;
      case "TopicCardValidations":
        isValid = TopicCardValidations.validateDescription(
          props.lastGeneratedDescription
        ).isValid;
        break;
      default:
        console.error("Unknown validation type");
    }

    props.replaceDescription(
      "description",
      props.lastGeneratedDescription,
      isValid
    );
    props.updateDescUI();
    setSavedDescription(true);
  };

  const modalBody = (
    <>
      <h2 id="description-title" aria-live="polite" aria-label="Description">
        <b>Description</b>
      </h2>
      <p
        id="generated-description"
        data-testid="gen-description"
        aria-live="polite"
        aria-label={props.lastGeneratedDescription}
      >
        {props.lastGeneratedDescription}
      </p>
      <p
        className="disclaimer"
        id="ai-disclaimer"
        aria-live="polite"
        aria-label="Response generated by AI. Verify accuracy and privacy restrictions before use"
      >
        Response generated by AI. Verify accuracy and privacy restrictions
        before use.
      </p>
    </>
  );

  const successBody = (
    <div className="success-body" style={{ marginBottom: 10 }}>
      <img
        src={theme === "dark" ? successDark : successLight}
        className="success-icon"
        alt="Check mark"
        id="success-icon"
        aria-labelledby="success-title"
      />
      <p
        style={{ fontWeight: "bold", fontSize: 28, marginBottom: 5 }}
        id="success-title"
      >
        Data generated successfully!
      </p>
      <p id="success-message" aria-live="polite">
        Your Gen AI data is ready. Explore and analyze the results.
      </p>
      <button
        id="success-ok-button"
        className="modal-button-second"
        style={{ backgroundColor: "var(--light-mode-secondary-button-color)" }}
        onClick={props.closeModal}
        aria-label="Close the modal and view results"
      >
        OK
      </button>
    </div>
  );

  const TwinkleImage = (src, top, left, delay) => {
    return (
      <img
        src={src}
        style={{
          position: "absolute",
          top: top,
          left: left,
          width: "36px",
          height: "34px",
          visibility: "visible",
          animationDelay: delay
        }}
        className="twinkle"
        alt="Twinkling star"
      />
    );
  };

  return (
    <>
      {  (
        <ModalCustom
          xClose={true}
          onClose={props.closeModal}
          modal_msg={savedDescription ? successBody : modalBody}
          modal_title="Gen AI on Search Tools"
          firstOptText="Accept"
          secondOption={false}
          modalSize={"description-modal " + (savedDescription ? "success" : "")}
          firstBtnAction={handleAccept}
          noFooter={savedDescription}
          zindex={11}
          firstOpAriaLabel="Accept the generated description"
          btn_1_class={"modal-button-second"}
        />
      )}
    </>
  );
};

export default GenAiModal;
