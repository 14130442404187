import StoreContext from "../../../store/storecontext";
import "./TCForm.css";
import TCLineItems from "./TopicCardInputs/TCLineItems";
import { useState, useEffect, useContext, useReducer } from "react";
import { useParams } from "react-router-dom";
import KeywordInput from "./TopicCardInputs/KeywordInput";
import RefinerPage from "./TopicCardInputs/RefinerPage";
import Location from "./TopicCardInputs/Location";
import PeopleInput from "./TopicCardInputs/PeopleInput";
import CommentsInput from "./TopicCardInputs/CommentsInput";
import TopicTitle from "./TopicCardInputs/TopicTitle";
import TopicURL from "./TopicCardInputs/TopicURL";
import TopicDescription from "./TopicCardInputs/TopicDescription";
import { TopicCardButtons } from "./TopicCardInputs/TopicCardButtons";
import TopicCardValidations from "../../../validationClasses/TopicCardValidations";
import CompareTableTC from "./CompareTableTC";
import { formatDate } from "../../FormatDate/DateFormat";
import ModalCustom from "../../Modal/ModalCustom";
import { ThemeContext } from "../../../ThemeContext";
import Preview from "../FormUI/Preview/Preview";
import TCPreview from "./TopicCardInputs/Preview/TCPreview";
import CommentHistory from "../FormUI/CommentHistory/CommentHistory";
import Toast from "../../Toast/Toast";
import NotFoundRequest from "../../PageNotFound/NotFoundRequest";
import GenAiModal from "../FormUI/GenAIDescription/GenAiModal";
import GenAiDescription from "../FormUI/GenAIDescription/GenAiDescription";

const TCForm = () => {
  let { id } = useParams();
  const [idFromUrl, setIdfromUrl] = useState(id);
  const store = useContext(StoreContext);
  const [theme] = useContext(ThemeContext);
  const [keywordInput, setKeywordInput] = useState("");
  const [refinerPageInput, setRefinerPageInput] = useState([]);
  const [locationInput, setLocationInput] = useState("");
  const [delegatesInput, setDelegatesInput] = useState("");
  const [contactsInput, setContactsInput] = useState("");
  const [isSupportContentChecked, setSupportContentChecked] = useState(
    idFromUrl === undefined
  );
  const [isSitesNewsChecked, setSitesNewsChecked] = useState(false);
  const [isLearningMaterialsChecked, setLearningMaterialsChecked] =
    useState(false);
  const [isSalesMaterialChecked, setSalesMaterialChecked] = useState(false);
  const [isAccenturePoliciesChecked, setAccenturePoliciesChecked] =
    useState(false);
  const [isInternalResourcesChecked, setInternalResourcesChecked] =
    useState(false);
  const [isExternalResourcesChecked, setExternalResourcesChecked] =
    useState(false);
  const [isGetInTouchChecked, setIsGetInTouchChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isFormReadOnly, setIsFormReadOnly] = useState(false);
  // for when the form is not ready yet to be submitted
  const [disableFormButtons, setDisableFormButtons] = useState(true);
  const [disableDraftBtn, setDisableDraftBtn] = useState(true);

  const [formContainsData, setFormContainsData] = useState(false);
  const [showPreviewTrigger, setShowPreviewTrigger] = useState(false);
  const [previewContent, setPreviewContent] = useState("");

  const [clipboardPillType, setClipboardPillType] = useState("Keyword");
  const [notFoundMessage, setNotFoundMessage] = useState(false);
  const urlCheckerService = store.services.urlCheckerService;

  const [showGenAiSpinner, setShowGenAiSpinner] = useState(false);
  const [showGenIADescModal, setShowGenIADescModal] = useState(false);
  const [disableRocketBtn, setDisableRocketBtn] = useState(true);
  const [lastGeneratedDescription, setLastGeneratedDescription] = useState("");
  const [genAISummaryContext, setGenAISummaryContext] = useState("");
  const [showGenAiWarning, setShowGenAiWarning] = useState(false);
  const genAIService = store.services.genIAService;

  const changeClipboardPillType = (pillType) => {
    setClipboardPillType(pillType);
  };

  const creationForm = {
    title: "",
    url: "",
    description: "",
    supportContent: [{ title: "", url: "" }],
    sitesNews: [],
    learningMaterials: [],
    salesMaterial: [],
    accenturePolicies: [],
    internalResources: [],
    externalResources: [],
    getInTouch: [],
    getInTouchNames: [],
    contacts: [],
    aid: [],
    countries: [],
    keyword: [],
    keywordsForReview: [],
    delegates: [],
    userIdDelegates: [],
    comments: "",
    arrComments: [],
    history: [],
    organization: [],
    status: "New",
    template: "standard",
    tcid: 0,
    tcrequestid: 0,
    yammerthreadid: null,
    submittedbyuserid: null,
    submittedbyenterpriseid: null,
    creationdate: undefined
  };
  const loadedForm = { ...creationForm, supportContent: [] };

  const getForm = () => {
    if (idFromUrl) {
      return loadedForm;
    } else return creationForm;
  };
  const [form, setForm] = useState(getForm());

  const [originalReqData, setOriginalReqData] = useState({
    tcData: null,
    tcReqData: null,
    formData: null
  });

  const creationFormStates = {
    title: null,
    url: null,
    description: null,
    supportContent: [
      {
        title: { state: null, errorMessage: null, inputClass: "" },
        url: { state: null, errorMessage: null, inputClass: "" }
      }
    ],
    sitesNews: [],
    learningMaterials: [],
    salesMaterial: [],
    accenturePolicies: [],
    internalResources: [],
    externalResources: [],
    contacts: [],
    aid: null,
    countries: null,
    keyword: null,
    delegates: [],
    comments: null
  };

  const loadedFormStates = { ...creationFormStates, supportContent: [] };

  const getInputStates = () => {
    if (idFromUrl) {
      return loadedFormStates;
    } else return creationFormStates;
  };

  const [inputStates, setInputStates] = useState(getInputStates());

  useEffect(() => {
    (async () => {
      if (sessionStorage.getItem("searchtools.config")) {
        let cfg = JSON.parse(sessionStorage.getItem("searchtools.config"));

        const refinerPageOptions = cfg[1].topiccard.application.map(
          (option) => {
            return (
              <option
                id={"tc-form-dropdown-refiner-page" + "-option-" + option.value}
                key={option.value}
                value={option.value}
              >
                {option.text}
              </option>
            );
          }
        );
        const locationOptions = cfg[1].topiccard.countries.map((option) => {
          return (
            <option
              id={"tc-form-dropdown-location" + "-option-" + option.value}
              key={option.value}
              value={option.value}
              data-testid={`dropdown-option-location`}
            >
              {option.text}
            </option>
          );
        });

        setRefinerPageInput(refinerPageOptions);
        setLocationInput(locationOptions);
      }
    })();
  }, []);

  const setKeywords = (state, action) => {
    switch (action.type) {
      case "reevaluateAll": {
        for (let index = 0; index < action.allKeywords.length; index++) {
          let keyword = {
            keyword: action.allKeywords[index],
            location: form.countries,
            refinerPage: form.aid,
            tcrequestid: parseInt(idFromUrl),
            abortController: null,
            service: store.services.topicCardService
          };
          TopicCardValidations.validateKeywords(keyword).then(
            ({ isValid, errorMessage }) => {
              if (form.status === "Draft" || form.wasdraft) {
                isValid = true;
                errorMessage = "";
              }

              if (isValid) {
                if (!form.keyword.includes(action.keyword)) {
                  //This check is for dev envs, because of the extra render of StrictMode
                  dispatchKeywordsReducer({
                    type: "addGreenKeyword",
                    keyword: action.allKeywords[index]
                  });
                }
              } else {
                dispatchKeywordsReducer({
                  type: "addRedKeyword",
                  keyword: action.allKeywords[index]
                });
              }
            }
          );
        }
        return { valid: [], invalid: [] };
      }
      case "addGreenKeyword": {
        const noRepeats = new Set(state.valid);
        noRepeats.add(action.keyword);
        return { valid: Array.from(noRepeats), invalid: state.invalid };
      } //Using a Set to avoid adding the same keyword
      case "addRedKeyword": //because of the <React.StrictMode> extra render
        const noRepeats = new Set(state.invalid);
        noRepeats.add(action.keyword);
        return { valid: state.valid, invalid: Array.from(noRepeats) };
    }
  };
  const [keywordsReducer, dispatchKeywordsReducer] = useReducer(setKeywords, {
    valid: [],
    invalid: []
  });
  useEffect(() => {
    if (!isLoading) {
      const revalidateKeywords = () => {
        const allKeywords = [...form.keyword, ...form.keywordsForReview];
        if (allKeywords.length > 0) {
          if (form.countries.length !== 0 && form.aid.length !== 0) {
            dispatchKeywordsReducer({
              type: "reevaluateAll",
              allKeywords: allKeywords
            });
          }
        }
      };
      revalidateKeywords();
    }
  }, [form.countries, form.aid]);
  useEffect(() => {
    if (!isLoading) {
      setForm({
        ...form,
        keyword: keywordsReducer.valid,
        keywordsForReview: keywordsReducer.invalid
      });
    }
  }, [keywordsReducer]);

  useEffect(() => {
    enableOrDisableButtons();
    enableOrDisableDraftBtn();
  }, [inputStates]);
  const updateLineItemState = (lineItem, value, index, field) => {
    if (isLoading) return;
    let states = { ...inputStates };
    states[lineItem][index][field] = value;
    setInputStates(states);
  };
  const setNewLineItem = (lineItem, value) => {
    if (isLoading) return;
    let formArray = { ...form };
    formArray[lineItem] = [...formArray[lineItem], { title: "", url: "" }];
    setForm(formArray);
  };
  const setNewLineItemState = (lineItem, value) => {
    if (isLoading) return;
    let statesArray = { ...inputStates };
    statesArray[lineItem] = value;
    setInputStates(statesArray);
  };

  const isFormValid = () => {
    let checkedLineItems = [
      {
        item: "supportContent",
        isChecked: isSupportContentChecked,
        state: inputStates.supportContent
      },
      {
        item: "sitesNews",
        isChecked: isSitesNewsChecked,
        state: inputStates.sitesNews
      },
      {
        item: "learningMaterials",
        isChecked: isLearningMaterialsChecked,
        state: inputStates.learningMaterials
      },
      {
        item: "salesMaterial",
        isChecked: isSalesMaterialChecked,
        state: inputStates.salesMaterial
      },
      {
        item: "accenturePolicies",
        isChecked: isAccenturePoliciesChecked,
        state: inputStates.accenturePolicies
      },
      {
        item: "internalResources",
        isChecked: isInternalResourcesChecked,
        state: inputStates.internalResources
      },
      {
        item: "externalResources",
        isChecked: isExternalResourcesChecked,
        state: inputStates.externalResources
      }
    ];

    let isFormValid;
    let isTitleValid = form.title !== null && inputStates.title;
    let isURLValid = inputStates.url !== false && form.url.length >= 0;
    let isRefinerPageValid = form.aid.length > 0;
    let isLocationValid = form.countries.length > 0;
    let areKeywordsValid = form.keyword.length > 0;
    let areLineItemsValid = true;
    let isDescriptionValid = TopicCardValidations.validateDescription(
      form.description
    ).isValid;
    var lineItemsChecked = 0;
    for (let index = 0; index < checkedLineItems.length; index++) {
      const item = checkedLineItems[index];
      if (item.isChecked && item.state.length > 0) {
        lineItemsChecked++;
        for (let j = 0; j < item.state.length; j++) {
          const state = item.state[j];
          let titleState = state.title.state !== null && state.title.state;
          let urlState = state.url.state !== null && state.url.state;
          areLineItemsValid = areLineItemsValid && titleState && urlState;
        }
      }
    }
    areLineItemsValid = areLineItemsValid && lineItemsChecked > 0;

    isFormValid =
      isTitleValid &&
      isURLValid &&
      isDescriptionValid &&
      isLocationValid &&
      isRefinerPageValid &&
      areKeywordsValid &&
      areLineItemsValid;

    return isFormValid;
  };

  const setFormStates = async (requestData, lineItemsStates) => {
    const isTitleValid =
      TopicCardValidations.titleLengthValidation(requestData.title).isValid &&
      TopicCardValidations.specialCharsValidation(requestData.title).isValid;

    let newFormStates = {
      title: isTitleValid,
      url: requestData.url.length > 0 ? validateURL(requestData.url) : null, //url is optional so maybe the request doesn't have one
      description: validateDescription(requestData.description),
      supportContent: lineItemsStates.supportContent,
      sitesNews: lineItemsStates.sitesNews,
      learningMaterials: lineItemsStates.learningMaterials,
      salesMaterial: lineItemsStates.salesMaterial,
      accenturePolicies: lineItemsStates.accenturePolicies,
      internalResources: lineItemsStates.internalResources,
      externalResources: lineItemsStates.externalResources,
      contacts: requestData.contacts.length > 0 ? true : null,
      aid: requestData.aid.length > 0,
      countries: true,
      keyword: true,
      delegates: requestData.delegates.length > 0 ? true : null,
      comments: null
    };

    await setInputStates(newFormStates);
  };

  const fillStateArray = (data) => {
    let arrOfStates = [];
    data.map((item) => {
      let newItem = {
        title: { state: true, errorMessage: null, inputClass: "is-valid" },
        url: { state: true, errorMessage: null, inputClass: "is-valid" }
      };
      arrOfStates.push(newItem);
    });

    return arrOfStates;
  };

  const setFormData = async (tcReqData) => {
    if ((formContainsData || tcReqData === null) && !isLoading) return;

    let dataToDisplay = {
      title: "",
      url: "",
      description: "",
      supportContent: [{ title: "", url: "" }],
      sitesNews: [{ title: "", url: "" }],
      learningMaterials: [{ title: "", url: "" }],
      salesMaterial: [{ title: "", url: "" }],
      accenturePolicies: [{ title: "", url: "" }],
      internalResources: [{ title: "", url: "" }],
      externalResources: [{ title: "", url: "" }],
      getInTouch: [],
      getInTouchNames: [],
      contacts: [],
      aid: [],
      countries: [],
      keyword: [],
      keywordsForReview: [],
      delegates: [],
      userIdDelegates: [],
      comments: "",
      arrComments: [],
      history: [],
      organization: [],
      status: "New",
      template: "standard",
      tcid: 0,
      tcrequestid: 0,
      yammerthreadid: null,
      submittedbyuserid: null,
      submittedbyenterpriseid: null,
      wasdraft: null
    };

    let lineItemsStates = {
      supportContent: [],
      sitesNews: [],
      learningMaterials: [],
      salesMaterial: [],
      accenturePolicies: [],
      internalResources: [],
      externalResources: []
    };

    await setSupportContentChecked(tcReqData.body.getsupport.length > 0);
    await setSitesNewsChecked(tcReqData.body.newsandarticles.length > 0);
    await setLearningMaterialsChecked(tcReqData.body.learn.length > 0);
    await setSalesMaterialChecked(tcReqData.body.salesmaterial.length > 0);
    await setAccenturePoliciesChecked(
      tcReqData.body.accenturepolicies.length > 0
    );
    await setInternalResourcesChecked(
      tcReqData.body.internalresources.length > 0
    );
    await setExternalResourcesChecked(
      tcReqData.body.externalresources.length > 0
    );

    await setIsGetInTouchChecked(tcReqData.body.getintouch.length > 0);

    dataToDisplay.title = tcReqData.title;
    dataToDisplay.url = tcReqData.url;
    dataToDisplay.description = tcReqData.description;

    dataToDisplay.supportContent = [...tcReqData.body.getsupport];
    lineItemsStates.supportContent = fillStateArray(tcReqData.body.getsupport);

    dataToDisplay.sitesNews = [...tcReqData.body.newsandarticles];
    lineItemsStates.sitesNews = fillStateArray(dataToDisplay.sitesNews);

    dataToDisplay.learningMaterials = [...tcReqData.body.learn];
    lineItemsStates.learningMaterials = fillStateArray(
      dataToDisplay.learningMaterials
    );

    dataToDisplay.salesMaterial = [...tcReqData.body.salesmaterial];
    lineItemsStates.salesMaterial = fillStateArray(dataToDisplay.salesMaterial);

    dataToDisplay.accenturePolicies = [...tcReqData.body.accenturepolicies];
    lineItemsStates.accenturePolicies = fillStateArray(
      dataToDisplay.accenturePolicies
    );

    dataToDisplay.internalResources = [...tcReqData.body.internalresources];
    lineItemsStates.internalResources = fillStateArray(
      dataToDisplay.internalResources
    );

    dataToDisplay.externalResources = [...tcReqData.body.externalresources];
    lineItemsStates.externalResources = fillStateArray(
      dataToDisplay.externalResources
    );

    tcReqData.body.getintouch.map((el) => {
      let entry = {
        eid: el.eid,
        fullname: el.fullname,
        role: el.role,
        url: el.url
      };
      if (el !== null || el !== undefined) {
        dataToDisplay.getInTouch.push(entry);
      }
      dataToDisplay.getInTouchNames.push(el.eid);
    });
    let tcCleanKeywords =
      tcReqData.keywordsraw.length > 0
        ? tcReqData.keywordsraw
        : tcReqData.keywords;
    let tcInvalidKeywords =
      tcReqData.invalidkeywordsraw.length > 0
        ? tcReqData.invalidkeywordsraw
        : tcReqData.invalidkeywords;

    dataToDisplay.contacts = [...tcReqData.contacts];
    dataToDisplay.aid = [...tcReqData.aid];
    dataToDisplay.countries = [...tcReqData.country];
    dataToDisplay.keyword = [...tcCleanKeywords];
    dataToDisplay.keywordsForReview = [...tcInvalidKeywords];
    dataToDisplay.delegates = [...tcReqData.delegates];
    dataToDisplay.userIdDelegates = [];
    dataToDisplay.comments = "";
    dataToDisplay.arrComments = [...tcReqData.comments];
    dataToDisplay.history = [...tcReqData.history];
    dataToDisplay.organization = [...tcReqData.organization];
    dataToDisplay.status = tcReqData.status;
    dataToDisplay.tcid = tcReqData.tcid;
    dataToDisplay.tcrequestid = tcReqData.docID;
    dataToDisplay.yammerthreadid = tcReqData.yammerthreadid;
    dataToDisplay.submittedbyuserid = tcReqData.submittedbyuserid;
    dataToDisplay.submittedbypeoplekey = tcReqData.submittedbypeoplekey;
    dataToDisplay.submittedbyuserid = tcReqData.submittedbyuserid;
    dataToDisplay.submittedbyenterpriseid = tcReqData.submittedbyenterpriseid;
    dataToDisplay.creationdate = tcReqData.creationdate;
    dataToDisplay.wasdraft = tcReqData.wasdraft;

    await setFormStates(
      dataToDisplay,
      JSON.parse(JSON.stringify(lineItemsStates))
    );

    // set the form mode
    const reqData = {
      isAdmin: store.state.isAdmin,
      submittedbyenterpriseid: dataToDisplay.submittedbyenterpriseid,
      delegates: dataToDisplay.delegates,
      status: dataToDisplay.status
    };
    const isReadOnly = await TopicCardValidations.validateFormMode(reqData);
    setIsFormReadOnly(isReadOnly);

    enableOrDisableButtons();

    return dataToDisplay;
  };

  const loadFormHandler = async (tcReqID) => {
    if (formContainsData) return;

    let tcQuery = null;
    let tcData = null;
    let lastRequest = null;
    let tcReqQuery = null;
    let tcReqData = {};

    setIsLoading(true);
    setDisableFormButtons(true);
    setDisableDraftBtn(true);

    tcReqQuery = await store.services.topicCardService.GetTopicCardRequest(
      tcReqID
    );

    let totalHits = tcReqQuery.data.hits.total.value;
    if (totalHits === 0) {
      setIsLoading(false);
      setNotFoundMessage(true);
    } else {
      if (tcReqQuery.data.hits.hits.length > 1) {
        lastRequest = tcReqQuery.data.hits.hits.pop();
        tcReqData = lastRequest._source;
      } else {
        tcReqData = tcReqQuery.data.hits.hits[0]._source;
        tcReqData.docID = parseInt(tcReqQuery.data.hits.hits[0]._id);
      }

      if (tcReqData.status === "Implemented" || tcReqData.tcid > 0) {
        tcQuery = await store.services.topicCardService.GetTopicCardByID(
          tcReqData.tcid
        );

        if (tcQuery.data.hits.hits.length > 1) {
          tcData = tcQuery.data.hits.hits.pop();
          tcData = tcData._source;
        } else {
          tcData = tcQuery.data.hits.hits[0]._source;
          tcData.docID = parseInt(tcQuery.data.hits.hits[0]._id);
        }
      }

      let currentOriginalData = { ...originalReqData };
      let deepCopyTcData = JSON.parse(JSON.stringify(tcData));
      let deepCopyTcReqData = JSON.parse(JSON.stringify(tcReqData));
      let reqDataObj = await setFormData(deepCopyTcReqData);

      currentOriginalData.tcData = deepCopyTcData;
      currentOriginalData.tcReqData = deepCopyTcReqData;
      currentOriginalData.formData = JSON.parse(JSON.stringify(reqDataObj));

      await setOriginalReqData({ ...currentOriginalData });

      if (
        parseInt(idFromUrl) > 0 &&
        !formContainsData &&
        form.title.length <= 0
      ) {
        await setForm(JSON.parse(JSON.stringify(reqDataObj)));
        setFormContainsData(true);
        setIsLoading(false);
      }
    }
  };

  // use effect for when the user loads request via browser url
  useEffect(() => {
    if (parseInt(idFromUrl) > 0) {
      loadFormHandler(idFromUrl);
    }
  }, [idFromUrl]);

  // validations and update states
  // useEffect for when a person is added on the get in touch field
  useEffect(() => {
    let getInTouchNames = form.getInTouch.map((el) => el.eid);
    updateForm("getInTouchNames", getInTouchNames);
  }, [form.getInTouch]);

  useEffect(() => {
    if (isLoading) return;
    if (form.delegates.length === 0) {
      form.contacts = [];
      updateForm("contacts", []);
      return;
    }
    let delegatesNames = [];
    form.delegates.map((el) => {
      delegatesNames.push(el.eid);
    });
    updateForm("contacts", delegatesNames);
  }, [form.delegates]);

  const updateFieldState = (field, value) => {
    let states = { ...inputStates };
    states[field] = value;
    setInputStates(states);
  };

  const updateForm = async (field, value) => {
    if (isLoading) return;
    let oldForm = { ...form };
    oldForm[field] = value;
    await setForm(oldForm);
  };

  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const [urlErrorMessage, setUrlErrorMessage] = useState("");
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");

  const validateTitle = async (value) => {
    if (value.trim().length === 0 || value === null) {
      let states = { ...inputStates };
      states["title"] = null;
      setInputStates(states);
      return;
    }
    const isInputValid = await TopicCardValidations.validateTitle(
      value,
      false,
      form.tcrequestid
    );
    if (form.status === "Draft" || form.wasdraft) {
      let titleRes = checkIfFieldIsEmptyOrInvalid(form.title);
      isInputValid.isValid = !titleRes.isFieldInvalid;
      isInputValid.errorMessage = titleRes.isFieldInvalid
        ? isInputValid.errorMessage
        : "";
    }
    let states = { ...inputStates };
    states["title"] = isInputValid.isValid;
    setInputStates(states);
    setTitleErrorMessage(isInputValid.errorMessage);
    return isInputValid;
  };

  const validateURL = async (value) => {
    if (value.length <= 0) {
      let states = { ...inputStates };
      states["url"] = null;
      setInputStates(states);
      return;
    }
    const isInputValid = await TopicCardValidations.validateURL(value);
    let states = { ...inputStates };
    states["url"] = isInputValid.isValid;
    setInputStates(states);
    await setUrlErrorMessage(isInputValid.errorMessage);
  };

  const [descriptionValidClass, setDescriptionValidClass] = useState("");

  const validateDescText = (isInputValid, inputValue) => {
    let currentCss = descriptionValidClass;
    if (inputValue.length === 0) {
      let states = { ...inputStates };
      states["description"] = null;
      setInputStates(states);
      currentCss = "";
      setDescriptionValidClass(currentCss);
    } else {
      let states = { ...inputStates };
      states["description"] = isInputValid.isValid;
      setInputStates(states);
      setDescriptionErrorMessage(isInputValid.errorMessage);
      currentCss = isInputValid.isValid ? "is-valid" : "is-invalid";
      setDescriptionValidClass(currentCss);
    }
  };
  const validateDescription = (inputValue) => {
    const isInputValid = TopicCardValidations.validateDescription(inputValue);
    validateDescText(isInputValid, inputValue);
  };

  useEffect(() => {
    validateDescription(form.description);
  }, [form.description]);

  const setContatcsMethod = (inputValue) => {
    setContactsInput(inputValue);
  };

  const [compareTableTrigger, setCompareTableTrigger] = useState(false);

  const showCompareTable = () => {
    setCompareTableTrigger(true);
  };

  const closeComparisonTable = () => {
    setCompareTableTrigger(false);
  };

  const enableOrDisableButtons = () => {
    let validationResult = isFormValid();
    setDisableFormButtons(!validationResult);
  };

  const checkDraftBtnForContacts = (_value, _field) => {
    enableOrDisableDraftBtn(_value, _field);
  };

  const checkIfFieldIsEmptyOrInvalid = (_field) => {
    let isFieldEmpty = _field.trim().length === 0;
    let isFieldInvalid = false;
    if (!isFieldEmpty) {
      let res = _field.trim().match(/([\\"])/g);
      isFieldInvalid = res?.length > 0;
    }

    return { isFieldEmpty: isFieldEmpty, isFieldInvalid: isFieldInvalid };
  };

  const enableOrDisableDraftBtn = (_value = null, _field = null) => {
    // check if any field has some value, if it does, enable save draft button
    // \ and " are invalid chars, disable draft button if any of the fields has this
    let titleRes = checkIfFieldIsEmptyOrInvalid(form.title);
    let isTitleEmpty = titleRes.isFieldEmpty;
    let isTitleInvalid = titleRes.isFieldInvalid;

    let urlRes = checkIfFieldIsEmptyOrInvalid(form.url);
    let isUrlEmpty = urlRes.isFieldEmpty;
    let isUrlInvalid = urlRes.isFieldInvalid;

    let descRes = checkIfFieldIsEmptyOrInvalid(form.description);
    let isDescEmpty = descRes.isFieldEmpty;
    let isDescInvalid = descRes.isFieldInvalid;

    let isRefinerEmpty = form.aid.length === 0;
    let isLocationEmpty = form.countries.length === 0;
    let isKeywordsEmpty = form.keyword.length === 0;
    let isDelegatesEmpty = form.contacts.length === 0;
    let isGetInTouchEmpty = form.getInTouchNames.length === 0;

    if (_value !== null) {
      if (_field === "delegates") {
        isDelegatesEmpty = !_value;
      } else if (_field === "getInTouch") {
        isGetInTouchEmpty = !_value;
      }
    }

    let fieldsSizeArr = [
      isTitleEmpty,
      isUrlEmpty,
      isDescEmpty,
      isRefinerEmpty,
      isLocationEmpty,
      isKeywordsEmpty,
      isDelegatesEmpty,
      isGetInTouchEmpty
    ];

    setDisableDraftBtn(true);

    for (let r = 0; r < fieldsSizeArr.length; r++) {
      if (!fieldsSizeArr[r]) {
        setDisableDraftBtn(false);
      }
    }

    let lineItemsNamesArr = [
      "supportContent",
      "accenturePolicies",
      "externalResources",
      "internalResources",
      "learningMaterials",
      "salesMaterial",
      "sitesNews"
    ];

    for (let i = 0; i < lineItemsNamesArr.length; i++) {
      let currLineItem = lineItemsNamesArr[i];
      const tempLineItem = form[currLineItem];
      try {
        if (tempLineItem?.length > 0) {
          for (let i = 0; i < tempLineItem.length; i++) {
            const tempLiEl = tempLineItem[i];
            let liTitleRes = checkIfFieldIsEmptyOrInvalid(tempLiEl.title);
            let liUrlRes = checkIfFieldIsEmptyOrInvalid(tempLiEl.url);

            if (!liTitleRes.isFieldEmpty && !liTitleRes.isFieldInvalid) {
              setDisableDraftBtn(false);
            } else if (liTitleRes.isFieldInvalid) {
              setDisableDraftBtn(true);
            }

            if (!liUrlRes.isFieldEmpty && !liUrlRes.isFieldInvalid) {
              setDisableDraftBtn(false);
            } else if (liUrlRes.isFieldInvalid) {
              setDisableDraftBtn(true);
            }
          }
        }
      } catch (error) {
        console.log("error :", error);
      }
    }

    if (isTitleInvalid || isUrlInvalid || isDescInvalid) {
      setDisableDraftBtn(true);
    }
  };

  const closeTCPreview = () => {
    setShowPreviewTrigger(false);
  };

  const showPreview = () => {
    setShowPreviewTrigger(true);
  };

  const deleteOldTooltips = () => {
    //delete unparented tooltips from the dom
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  // delete unparented tooltips
  // when the user comes from review curated content or
  // content searcher
  useEffect(() => {
    deleteOldTooltips();
  }, []);

  let regex = urlCheckerService.textRegex;
  const isUrlClean = () => {
    return !form.url.match(regex);
  };

  const openGenAiModal = (e) => {
    e.preventDefault();
    setShowGenIADescModal(true);
  };

  const updateDescUI = () => {
    //adds the descrption generated by the gen ai
    // to the description field UI (form data is saved in another method)
    let descriptionEL = document.getElementById(`description`);
    descriptionEL.value = lastGeneratedDescription;
    descriptionEL.classList.add("is-valid");
  };

  var userInputTimeout;
  useEffect(() => {
    var isCancelled = false;

    if(form.url.length === 0) {
      GenAiDescription.enableRocketBtn(false);
      setDisableRocketBtn(true);
      setShowGenAiSpinner(false);
      setShowGenAiWarning(false);
    } else {
      if(isFormReadOnly){
        GenAiDescription.enableRocketBtn(false);
        setDisableRocketBtn(true);
        setShowGenAiSpinner(false);
        setShowGenAiWarning(false);
        return;
      }
      try {
        userInputTimeout = setTimeout(async () => {
        if (isUrlClean() && form.url !== "") {
          //get Gen ai description
          GenAiDescription.showRocketBtn(false);
          setShowGenAiSpinner(true);
          try{
            let genAiDescObj = await GenAiDescription.GetGenAiDescription(
              form.url,
              validateURL(form.url),
              urlCheckerService,
              genAIService);
              
          //Check if call was cancelled(the user modified the input)
          if(isCancelled || genAiDescObj === undefined) {
            setShowGenAiSpinner(false);
            GenAiDescription.showRocketBtn(true);
            setDisableRocketBtn(true);
            setShowGenAiWarning(false);
            return
          }
          if(genAiDescObj.hasOwnProperty("showRocketBtn")){
            GenAiDescription.showRocketBtn(true);
            setShowGenAiSpinner(false);
            if(genAiDescObj.description !== "" && 
              !genAiDescObj.description.includes("There is not enough information about this site")){
                console.log("enablerocketbtn ", genAiDescObj.enableRocketBtn)
                GenAiDescription.enableRocketBtn(genAiDescObj.enableRocketBtn);
                setDisableRocketBtn(!genAiDescObj.enableRocketBtn);
                setShowGenAiWarning(false);
              } else {
                GenAiDescription.enableRocketBtn(false);
                setDisableRocketBtn(true);
                setShowGenAiWarning(true);
              };
            }
            setLastGeneratedDescription(genAiDescObj.description);
          } catch (error){
            setShowGenAiWarning(true);
            setShowGenAiSpinner(false)
            GenAiDescription.showRocketBtn(true);
            GenAiDescription.enableRocketBtn(false);
            setDisableRocketBtn(true);
          }
          }
      }, 800);
      
      return () => {
        clearTimeout(userInputTimeout);
        isCancelled = true;
      };
    } catch (error) {
      
    }
  }
  }, [form.url]);


  return (
    <>
      {notFoundMessage ? (
        <NotFoundRequest />
      ) : (
        <div className="overlay">
          <form
            data-testid="topic-card-form"
            className="form-inline topic-card-form"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            {isLoading ? (
              <div className="overlay-spinner modal-fade" id="loading">
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border text-light-mode-purple"
                    role="status"
                  ></div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {compareTableTrigger ? (
              <div className="tc_compare_sticky_div">
                <ModalCustom
                  darkMode={theme === "dark"}
                  onClick={closeComparisonTable}
                  onClose={closeComparisonTable}
                  modal_msg={
                    <CompareTableTC
                      originalReqData={originalReqData}
                      currentData={form}
                      closeComparisonTable={closeComparisonTable}
                    />
                  }
                  btn_1_class={
                    theme === "dark"
                      ? "btn btn-dark-mode-purple"
                      : "btn btn-light-mode-purple"
                  }
                  modalSize={"modal-xl"}
                  noHeader={true}
                />
              </div>
            ) : (
              <></>
            )}
            {formContainsData && parseInt(idFromUrl) > 0 ? (
              <div id="request-info-div" className="tc-request-info-parent-div">
                <div className="tc-request-info-title-div">
                  Topic Card Request ID {form.tcrequestid}
                </div>
                <div className="request-info-child-div">
                  <div className="request-info-block">
                    Last Modified Date:{" "}
                    <p>
                      {formatDate(
                        originalReqData.tcReqData.lastmodificationdate
                      )}
                    </p>
                  </div>
                  <div className="request-info-block">
                    Status: <p>{form.status}</p>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="title-url">
              <div className="col-7 title-url">
                <TopicTitle
                  tooltipFor={"title-input"}
                  value={form.title}
                  setValue={updateForm}
                  inputState={inputStates}
                  setInputState={() => {}}
                  titleErrorMessage={titleErrorMessage}
                  validateTitle={validateTitle}
                  disableField={isFormReadOnly}
                />
                <TopicURL
                  tooltipFor={"url-input"}
                  value={form.url}
                  setValue={updateForm}
                  inputState={inputStates}
                  setInputState={() => {}}
                  urlErrorMessage={urlErrorMessage}
                  validateURL={validateURL}
                  disableField={isFormReadOnly}
                  urlCheckerService={urlCheckerService}
                  openGenAiModal={openGenAiModal}
                  disableGenIaBtn={disableRocketBtn}
                  showGenAiSpinner={showGenAiSpinner}
                  showGenAiWarning={showGenAiWarning}
                  hideGenAiFeature={isFormReadOnly}
                />
              </div>
            </div>
            <div className="col-6 form-group description">
              <TopicDescription
                tooltipFor={"description-input"}
                value={form.description}
                setValue={updateForm}
                inputState={inputStates}
                descriptionErrorMessage={descriptionErrorMessage}
                validateDescription={validateDescription}
                validateField={TopicCardValidations.validateDescription}
                disableField={isFormReadOnly}
                validInputClass={descriptionValidClass}
              />
              <div className="row my-4">
                <div className="col-12">
                  <span className="span-title">
                    Select the section for your topic card and add a link to
                    each of them
                  </span>
                  <br></br>
                  <TCLineItems
                    title={"Support Content"}
                    isDiamond={false}
                    isChecked={isSupportContentChecked}
                    setIsChecked={setSupportContentChecked}
                    lineItem={form.supportContent}
                    setLineItem={updateForm}
                    lineItemId={"support-content"}
                    field="supportContent"
                    state={inputStates.supportContent}
                    setState={updateLineItemState}
                    setNewLineItem={setNewLineItem}
                    setNewLineItemState={setNewLineItemState}
                    disableField={isFormReadOnly}
                    formContainsData={formContainsData}
                  />
                  <TCLineItems
                    title={"Sites & News"}
                    isDiamond={false}
                    isChecked={isSitesNewsChecked}
                    setIsChecked={setSitesNewsChecked}
                    lineItem={form.sitesNews}
                    setLineItem={updateForm}
                    lineItemId={"sites-news"}
                    field="sitesNews"
                    state={inputStates.sitesNews}
                    setState={updateLineItemState}
                    setNewLineItem={setNewLineItem}
                    setNewLineItemState={setNewLineItemState}
                    disableField={isFormReadOnly}
                    formContainsData={formContainsData}
                  />
                  <TCLineItems
                    title={"Learning Materials"}
                    isDiamond={false}
                    isChecked={isLearningMaterialsChecked}
                    setIsChecked={setLearningMaterialsChecked}
                    lineItem={form.learningMaterials}
                    setLineItem={updateForm}
                    lineItemId={"learning-materials"}
                    field="learningMaterials"
                    state={inputStates.learningMaterials}
                    setState={updateLineItemState}
                    setNewLineItem={setNewLineItem}
                    setNewLineItemState={setNewLineItemState}
                    disableField={isFormReadOnly}
                    formContainsData={formContainsData}
                  />
                  <TCLineItems
                    title={"Sales Material"}
                    isDiamond={false}
                    isChecked={isSalesMaterialChecked}
                    setIsChecked={setSalesMaterialChecked}
                    lineItem={form.salesMaterial}
                    setLineItem={updateForm}
                    lineItemId={"sales-materials"}
                    field="salesMaterial"
                    state={inputStates.salesMaterial}
                    setState={updateLineItemState}
                    setNewLineItem={setNewLineItem}
                    setNewLineItemState={setNewLineItemState}
                    disableField={isFormReadOnly}
                    formContainsData={formContainsData}
                  />
                  <TCLineItems
                    title={"Accenture Policies"}
                    isDiamond={false}
                    isChecked={isAccenturePoliciesChecked}
                    setIsChecked={setAccenturePoliciesChecked}
                    lineItem={form.accenturePolicies}
                    setLineItem={updateForm}
                    lineItemId={"accenture-policies"}
                    field="accenturePolicies"
                    state={inputStates.accenturePolicies}
                    setState={updateLineItemState}
                    setNewLineItem={setNewLineItem}
                    setNewLineItemState={setNewLineItemState}
                    disableField={isFormReadOnly}
                    formContainsData={formContainsData}
                  />
                  <TCLineItems
                    title={"Internal Resources"}
                    isDiamond={true}
                    isChecked={isInternalResourcesChecked}
                    setIsChecked={setInternalResourcesChecked}
                    lineItem={form.internalResources}
                    setLineItem={updateForm}
                    lineItemId={"internal-resources"}
                    field="internalResources"
                    state={inputStates.internalResources}
                    setState={updateLineItemState}
                    setNewLineItem={setNewLineItem}
                    setNewLineItemState={setNewLineItemState}
                    disableField={isFormReadOnly}
                    formContainsData={formContainsData}
                  />
                  <TCLineItems
                    title={"External Resources"}
                    isDiamond={true}
                    isChecked={isExternalResourcesChecked}
                    setIsChecked={setExternalResourcesChecked}
                    lineItem={form.externalResources}
                    setLineItem={updateForm}
                    lineItemId={"external-resources"}
                    field="externalResources"
                    state={inputStates.externalResources}
                    setState={updateLineItemState}
                    setNewLineItem={setNewLineItem}
                    setNewLineItemState={setNewLineItemState}
                    disableField={isFormReadOnly}
                    formContainsData={formContainsData}
                  />

                  <PeopleInput
                    delegatesInput={contactsInput}
                    setDelegatesInput={setContatcsMethod}
                    selectedDelegates={form.getInTouchNames}
                    contacts={true}
                    placeholderText="Contacts of this Topic Card"
                    updateValue={updateForm}
                    inputState={inputStates}
                    setInputState={updateFieldState}
                    field="getInTouch"
                    contactArray={form.getInTouch}
                    disableField={isFormReadOnly}
                    requestStatus={form.status}
                    changeClipboardPillType={changeClipboardPillType}
                    checkDraftBtn={checkDraftBtnForContacts}
                  />
                  <Location
                    tooltipFor={"location-dropdown"}
                    locationInput={locationInput}
                    selectedLocation={form.countries}
                    selectedKeywords={form.keyword}
                    selectedRefinerPage={form.aid}
                    keywordsForReview={form.keywordsForReview}
                    updateValue={updateForm}
                    setInputState={updateFieldState}
                    disableField={isFormReadOnly}
                    requestStatus={form.status}
                  />
                  <RefinerPage
                    tooltipFor={"refiner-page-dropdown"}
                    refinerPageInput={refinerPageInput}
                    selectedRefinerPage={form.aid}
                    selectedKeywords={form.keyword}
                    selectedLocation={form.countries}
                    keywordsForReview={form.keywordsForReview}
                    updateValue={updateForm}
                    setInputState={updateFieldState}
                    disableField={isFormReadOnly}
                    requestStatus={form.status}
                  />
                  <KeywordInput
                    tooltipFor={"keyword-input"}
                    keywordInput={keywordInput}
                    selectedKeywords={form.keyword}
                    setKeywordInput={setKeywordInput}
                    updateFormField={updateForm}
                    selectedRefinerPage={form.aid}
                    selectedLocation={form.countries}
                    inputState={inputStates}
                    setInputState={updateFieldState}
                    keywordsForReview={form.keywordsForReview}
                    disableField={isFormReadOnly}
                    revalidateForm={enableOrDisableButtons}
                    tcrequest={form.tcrequestid}
                    requestStatus={form.status}
                    changeClipboardPillType={changeClipboardPillType}
                  />
                  <PeopleInput
                    tooltipFor={"delegates-input"}
                    delegatesInput={delegatesInput}
                    setDelegatesInput={setDelegatesInput}
                    selectedDelegates={form.contacts}
                    contacts={false}
                    placeholderText="Delegates who can edit this request (optional)"
                    updateValue={updateForm}
                    inputState={inputStates}
                    setInputState={() => {}}
                    field="delegates"
                    contactArray={form.delegates}
                    disableField={isFormReadOnly}
                    requestStatus={form.status}
                    changeClipboardPillType={changeClipboardPillType}
                    checkDraftBtn={checkDraftBtnForContacts}
                  />
                  {!isFormReadOnly && (
                    <CommentsInput
                      tooltipFor={"comments-input"}
                      field="comments"
                      updateValue={updateForm}
                      inputState={inputStates}
                      setInputState={setInputStates}
                    />
                  )}
                  {formContainsData && parseInt(idFromUrl) > 0 ? (
                    <>
                      {form.arrComments.map((comment, idx) => (
                        <div className="comments" key={idx}>
                          <CommentHistory
                            user={comment.user}
                            date={comment.dateUTC}
                            comment={comment.comment}
                            commentCSS={"comment-tc"}
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <Toast
              toastText={clipboardPillType + " copied to the clipboard!"}
              toastKey="copyToClipboard"
            />
          </form>

          {isFormReadOnly ? (
            <></>
          ) : (
            <TopicCardButtons
              isAdmin={store.state.isAdmin}
              disableField={isFormReadOnly}
              disableButton={disableFormButtons}
              disableDraftButton={disableDraftBtn}
              form={form}
              setIsLoading={setIsLoading}
              callCompareTable={showCompareTable}
              originalData={originalReqData}
              idFromUrl={idFromUrl}
              openPreview={(e) => showPreview(e)}
              setPreviewContent={setPreviewContent}
              yammerService={store.services.yammerService}
            />
          )}
          {showPreviewTrigger && (
            <Preview
              preview={<TCPreview preview={previewContent} />}
              closePreview={closeTCPreview}
            />
          )}
          {showGenIADescModal && (
            <GenAiModal
              closeModal={() => setShowGenIADescModal(false)}
              lastGeneratedDescription={lastGeneratedDescription}
              setLastGeneratedDescription={setLastGeneratedDescription}
              replaceDescription={updateForm}
              updateDescUI={updateDescUI}
              url={form.url}
              context={genAISummaryContext}
              TopicCardValidations={TopicCardValidations}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TCForm;
