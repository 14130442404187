
// this is a handler for the gen ai description feature

const showRocketBtn = (_showImg)=>{
  try {
    let genAiRocketBtn = document.querySelector(".gen-ai-rocket-button-selector");
    genAiRocketBtn.style.visibility = _showImg ? "visible" : "hidden";

    let divHolder = document.querySelector(".kw-div-elements-holder-selector");
    _showImg ? divHolder.classList.add("show-rocket-button-position") : divHolder.classList.add("show-rocket-button-position");
  } catch (error) {
    console.log("Element is hidden from the dom!");
  }
}

const enableRocketBtn = (_enableBtn)=>{
  try {
    let genAiRocketBtn = document.querySelector(".gen-ai-rocket-button-selector");
    genAiRocketBtn.disabled = !_enableBtn;
  } catch (error) {
    console.log("Element is hidden from the dom!");
  }

}


const GetGenAiDescription = async (_url, _isValidUrl, _urlCheckerService, _genIAService)=>{
  if(!_isValidUrl) return;
    let url = _url;
    let genAiDescObj = {
        description : "",
        showRocketBtn : true,
        enableRocketBtn : false
    }
    const bbDescriptionLimit = process.env.REACT_APP_BB_DESCRIPTION_LIMIT;
    const tcDescriptionLimit = process.env.REACT_APP_DESCRIPTION_LIMIT;
    if (_urlCheckerService.urlRegex.test(url)) {
      url = url.replace(/\/+$/, '');

        let genAiDescTextQuery = await _genIAService.GetGenIADescription(bbDescriptionLimit || tcDescriptionLimit, url);
        let genAiDescText = genAiDescTextQuery.data.result;
        let enableRocketBtn = !genAiDescTextQuery.data.result.includes("There is not enough information about this site");
        genAiDescObj.description = genAiDescText;
        genAiDescObj.enableRocketBtn = enableRocketBtn;
        return genAiDescObj;
    }
    return genAiDescObj;
}


export default {
    GetGenAiDescription : GetGenAiDescription,
    showRocketBtn : showRocketBtn,
    enableRocketBtn : enableRocketBtn 
};